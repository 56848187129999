import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';

import logo from 'src/images/logoNav.png';
import { navLinks } from '../../utils/navMenu';
import { SideIcons } from '../../utils/navMenu';

import { Link } from '../../utils/general';

import { useMedia } from 'react-use';

const mainHeader = ({ bgColor = 'bg-white' }) => {
  const [showMenu, setShowMenu] = useState('hidden');
  const Container = styled.div`
    ${tw`flex items-center justify-between py-6 md:space-x-10`}
    ${bgColor == 'bg-white' && tw`border-b-2 border-gray-100`}
  `;
  const isMd = useMedia('(min-width: 1240px)');

  const StyledDiv = styled.div`

  .more-btn{
    ${tw`flex items-center justify-center pb-1 text-xs font-semibold tracking-wide transition duration-300 border-b-2 border-transparent lg:text-sm text-primary-900 hover:border-primary-500 hocus:text-primary-900 hover:no-underline`}
  }
  .drop-down {
    display: none;
}
&:hover{

  .drop-down {
    display: block;
}

}
.more-btn:hover {
    display: flex;
    color: balck;
}
`


  return (
    <div tw="relative  z-50" className={bgColor}>
      <div tw="max-w-7xl mx-auto px-4 sm:px-6">
        <Container>
          <div tw="flex justify-start flex-1">
            <Link to="/">
              <img tw="h-20 w-auto" src="https://cdn.shopify.com/s/files/1/0278/3612/1223/files/image_2.png?v=1611598181" alt="" />
            </Link>
          </div> 
          <div tw="-mr-2 -my-2 md:hidden">
            <button 
              onClick={() => {
                setShowMenu('visible');
              }}
              type="button"
              tw="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            >
              <span tw="sr-only">Open menu</span>
              <svg
                tw="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <nav tw="hidden md:flex space-x-4">
            {!isMd
              ? navLinks.slice(0,4).map((e) => (
                e.isAvailbelOnTablet ? (
                  <Link
                    to={e.url}
                    tw="text-xs  lg:text-sm
              font-semibold tracking-wide transition duration-300 text-primary-900
              pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-900 hover:no-underline flex justify-center items-center"
                  >
                    {e.text}
                  </Link>
                ) : null
                ))
              : navLinks.slice(0,6).map((e) =>
                  <Link
                  to={e.url}
                  tw="text-xs lg:text-sm
              font-semibold tracking-wide transition duration-300 text-primary-900
              pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-900 hover:no-underline flex justify-center items-center"
                >
                  {e.text}
                </Link>
                )}



            <StyledDiv className="relative pt-1">
                    <button className="more-btn">
                        More
                        <svg className="ml-1 h-5 w-5 text-primary-900 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
                    </button>
                    
                  <div className="drop-down" tw="absolute z-10 left-auto transform -translate-x-16 pt-3 px-2 sm:px-0 w-40 lg:w-48">
                           
                        <div tw="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-gray-100">
                  <div tw="pt-2 pb-2 px-5">
                    <div tw="flex  flex-col mt-2">
              {!isMd
              ? navLinks.slice(3,navLinks.length).map((e) =>
              <Link
              to={e.url}
              tw="text-xs lg:text-sm
          font-semibold tracking-wide transition duration-300 text-primary-900
          pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-900 hover:no-underline flex justify-center items-center"
            >
              {e.text}
            </Link>
            )
              : navLinks.slice(6,navLinks.length).map((e) =>
                  <Link
                  to={e.url}
                  tw="text-xs lg:text-sm
              font-semibold tracking-wide transition duration-300 text-primary-900
              pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-900 hover:no-underline flex justify-center items-center"
                >
                  {e.text}
                </Link>
                )}
                    </div>
                  </div>
                </div>
                  </div>
            </StyledDiv>


            <Link
                  to="/pages/contact-us/"
                  tw="text-xs lg:text-sm
              font-semibold tracking-wide transition duration-300 text-primary-900
              pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-900 hover:no-underline flex justify-center items-center"
                >
                  Contact Us
                </Link>

            {SideIcons.map((e) => (
              <a
                href={e.url}
                tw="text-lg lg:text-sm lg:mx-3
                font-semibold tracking-wide transition duration-300 text-primary-900
                pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-900 hover:no-underline flex justify-center items-center"
                onClick={e.onClick}
              >
                {e.text}
              </a>
            ))}
          </nav>
        </Container>
      </div>

      <div
        tw="absolute top-0 inset-x-0 p-2 transition transform origin-top-right"
        className={showMenu}
      >
        <div tw="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-100">
          <div tw="pt-5 pb-6 px-5">
            <div tw="flex items-center justify-between">
              <div>
                <img 
                  tw="h-8 w-auto"
                  src="https://cdn.shopify.com/s/files/1/0278/3612/1223/files/image_2_300x.png?v=1611598181"
                  alt="Workflow"
                />
              </div>
              <div tw="-mr-2">
                <button
                  onClick={() => {
                    setShowMenu('hidden');
                  }}
                  type="button"
                  tw="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <span tw="sr-only">Close menu</span>
                  <svg
                    tw="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div tw="flex  flex-col mt-6">
              {navLinks.map((e) => (
                <Link
                  to={e.url}
                  tw="text-sm my-2 lg:text-sm lg:mx-3 lg:my-0
                font-semibold tracking-wide transition duration-300 text-primary-900
                pb-1 border-b-0 border-transparent hover:border-primary-500 hocus:text-primary-900 hover:no-underline"
                >
                  {e.text}
                </Link>
              ))}
            </div>
          </div>
          <div tw="py-6 px-5 space-y-6">
            <div>
              <a
                href="#"
                tw="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:text-white bg-primary-900 hover:bg-primary-900 hover:no-underline"
              >
                Sign up
              </a>
              <p tw="mt-6 text-center text-base font-medium text-gray-500">
                Existing customer?
                <a
                  href="#"
                  tw="text-primary-900 hover:text-primary-900 hover:no-underline"
                >
                  Sign in
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default mainHeader;
